body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-gutter: stable both-edges;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent
}

::-webkit-scrollbar-track {
    background: transparent
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 167, 111, 0.5);
}

::-webkit-scrollbar-corner {
    overflow: hidden;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 167, 111, 0.5);
}
